import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import fedHolidays from "@18f/us-federal-holidays";

import "./header.css";

//var nameOfDay = currentDate.toLocaleString(undefined, {
//    weekday: 'long'
// })

const Header = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const showroomHours = () => {
    const isAHoliday = fedHolidays.isAHoliday();
    if (isAHoliday) {
      return "showroom closed";
    }

    var currentDate = new Date();
    var day = currentDate.getDay();

    var startTime = "09:00:00";
    var endTime = "16:00:00";

  
    if (day >= 1 && day <= 5) {
      //weekday 10-4
      startTime = "09:00:00";
      endTime = "16:00:00";

      var startDate = new Date(currentDate.getTime());
      startDate.setHours(startTime.split(":")[0]);
      startDate.setMinutes(startTime.split(":")[1]);
      startDate.setSeconds(startTime.split(":")[2]);

      var endDate = new Date(currentDate.getTime());
      endDate.setHours(endTime.split(":")[0]);
      endDate.setMinutes(endTime.split(":")[1]);
      endDate.setSeconds(endTime.split(":")[2]);

      var valid = startDate < currentDate && endDate > currentDate;

      if (valid) {
        return "open until 4pm";
      }
    } else if (day == 6) {
      //sat 10-1
      startTime = "10:00:00";
      endTime = "13:00:00";

      var startDate = new Date(currentDate.getTime());
      startDate.setHours(startTime.split(":")[0]);
      startDate.setMinutes(startTime.split(":")[1]);
      startDate.setSeconds(startTime.split(":")[2]);

      var endDate = new Date(currentDate.getTime());
      endDate.setHours(endTime.split(":")[0]);
      endDate.setMinutes(endTime.split(":")[1]);
      endDate.setSeconds(endTime.split(":")[2]);

      var valid = startDate < currentDate && endDate > currentDate;

      if (valid) {
        return "open until 1pm";
      }
    } else if (day == 7) {
      return "showroom closed";
    }
    return "showroom closed";
  };

  return (
    <header
      data-role="Header"
      className={`header-header navbar ${props.rootClassName} `}
      style={{ height: "150px" }}
    >
      <div className="animate__animated animate__fadeInDown header-container">
      <Link to="/">
      
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="animate__animated animate__rotateInDownLeft header-image"
        />
        </Link>
        <div className="header-container1">
          <span
            className="header-text"
            style={{
              color: showroomHours() !== "showroom closed" ? "green" : "red",
            }}
          >
            {showroomHours()}
          </span>
          <span className="header-text1">{props.text1}</span>
          <span className="header-text2">{props.text2}</span>
        </div>
      </div>
      <div className="animate__animated animate__fadeInUp header-nav">
        <nav className="header-nav1">
          <Link
            to="/quote"
            className="header-navlink01 hover-underline-animation"
            style={{ color: "black" }}
          >
            {props.text4}
          </Link>
          <Link
            to="/inventory"
            className="header-navlink02 hover-underline-animation"
            style={{ color: "black" }}
          >
            {props.text5}
          </Link>
          <Link
            to="/gallery"
            className="header-navlink03 hover-underline-animation"
            style={{ color: "black" }}
          >
            {props.text6}
          </Link>
          <Link
            to="/contact"
            className="header-navlink04 hover-underline-animation"
            style={{ color: "black"}}
          >
            {props.text7}
          </Link>
        </nav>
      </div>
      
      <div className="animate__animated animate__fadeInDown header-icon-group"  >
        <a
          href="https://www.instagram.com/preferred.marble/"
          target="_blank"
          rel="noreferrer noopener"
          className="hover-underline-animation"
        >
          <svg viewBox="0 0 877.7142857142857 1024" className="header-icon02">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              className="hover-underline-animation"
            ></path>
          </svg>
        </a>
        <a
          href="https://www.facebook.com/Preferred2005/"
          target="_blank"
          rel="noreferrer noopener"
          className="hover-underline-animation"
        >
          <svg viewBox="0 0 602.2582857142856 1024" className="header-icon02">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              className=""
            ></path>
          </svg>
        </a>
      </div>
      <div className="header-container2">
        <div data-type="BurgerMenu" className="header-burger-menu">
          <svg
            viewBox="0 0 1024 1024"
            style={{ width: "25px", height: "50px" }}
            className="header-icon04"
          >
            <path
              onClick={toggleClass}
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
      </div>
      <div
        data-type="MobileMenu"
        style={{ display: isActive ? "block" : "none" }}
        className={"header-mobile-menu"}
      >
        <div className="header-nav2">
          <div className="header-container3">
            <img
              alt={props.image_alt1}
              src={props.image_src}
              className="header-image1"
            />
            <div
              data-type="CloseMobileMenu"
              className="header-close-mobile-menu"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="header-icon06"
                style={{ width: "25px", height: "50px" }}
                onClick={toggleClass}
              >
                <path
                  style={{ width: "100%", height: "100%" }}
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  className=""
                ></path>
              </svg>
            </div>
          </div>
          <div className="">
            <a
              href="https://www.instagram.com/preferred.marble/"
              target="_blank"
              rel="noreferrer noopener"
              className=""
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="header-icon08"
              >
                <path
                  d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  className=""
                ></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/preferred.marble"
              target="_blank"
              rel="noreferrer noopener"
              className=""
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="header-icon10"
              >
                <path
                  d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
                  className=""
                ></path>
              </svg>
            </a>
          </div>
          <div className="header-container4">
            <div className="header-container5">
              <div className="header-container6">
                <nav className="header-nav3">
                  <Link to="/" className="header-navlink05">
                    {props.text31}
                  </Link>
                  <Link to="/quote" className="header-navlink06">
                    {props.text41}
                  </Link>
                  <Link to="/inventory" className="header-navlink07">
                    {props.text51}
                  </Link>
                  <Link to="/gallery" className="header-navlink08">
                    {props.text61}
                  </Link>
                  <Link to="/contact" className="header-navlink09">
                    {props.text71}
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  text41: "Quote",
  text51: "Inventory",
  text4: "Quote",
  image_alt: "logo",
  text31: "Home",
  text3: "Home",
  image_src:
    "https://static.wixstatic.com/media/113df6_ec65e722ad3f4bf194fbb455ec761378~mv2.png/v1/fill/w_110,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Preffered%20transparent.png",
  rootClassName: "",
  link_text: "https://example.com",
  text1: "586-294-6360",
  text61: "Gallery",
  text2: "32205 Groesbeck Hwy, Fraser, MI 48026",
  image_alt1: "image",
  text6: "Gallery",
  text71: "Contact",
  image_src1:
    "https://presentation-website-assets.teleporthq.io/logos/logo.png",
  text: "Open until 4pm",
  text7: "Contact",
  text5: "Inventory",
};

Header.propTypes = {
  text41: PropTypes.string,
  text51: PropTypes.string,
  text4: PropTypes.string,
  image_alt: PropTypes.string,
  text31: PropTypes.string,
  text3: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
  link_text: PropTypes.string,
  text1: PropTypes.string,
  text61: PropTypes.string,
  text2: PropTypes.string,
  image_alt1: PropTypes.string,
  text6: PropTypes.string,
  text71: PropTypes.string,
  image_src1: PropTypes.string,
  text: PropTypes.string,
  text7: PropTypes.string,
  text5: PropTypes.string,
};

export default Header;
