import React from 'react'

import PropTypes from 'prop-types'

import './blurb.css'

const Blurb = (props) => {
  return (
    <div className={`blurb-container ${props.rootClassName} `}>
      <div className="blurb-hero">
        <div className="blurb-container1">
          <h1 className="blurb-text">{props.heading}</h1>
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="blurb-image"
          />
          <span className="blurb-text1">{props.text}</span>
          <div className="blurb-btn-group">
            <button className="blurb-button button">
              <span className="blurb-text2">
                <span className="">{props.text1}</span>
              </span>
            </button>
          </div>
        </div>
        <div className="blurb-container2">
          <img alt="image" src={props.image_src} className="blurb-image1" />
        </div>
      </div>
    </div>
  )
}

Blurb.defaultProps = {
  image_src2: 'https://ciot.com/pub/media/banner_press_700x480.jpg',
  text1: 'See our Work',
  image_src1: 'https://ciot.com/pub/media/banner_press_700x480.jpg',
  rootClassName: '',
  text: "For the last 4 generations Preferred Marble & Granite has been voted #1 in Michigan and recognized as a trusted granite fabricator throughout the USA and your number one source for all things granite. Where we only employ professional, experienced installers who go through 5 years of training before handling your material. We are 1 of 2 shops in Michigan that can cut Cambria without a middle man and we also specialize in custom marble masonry! We hope you enjoy our services and products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us!",
  image_alt: 'image',
  image_src: 'https://ciot.com/pub/media/banner_press_700x480.jpg',
  image_alt1: 'image',
  heading: 'Our story',
}

Blurb.propTypes = {
  image_src2: PropTypes.string,
  text1: PropTypes.string,
  image_src1: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  heading: PropTypes.string,
}

export default Blurb
