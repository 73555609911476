import React from 'react'

import PropTypes from 'prop-types'

import './image-button.css'

const ImageButton = (props) => {
  return (
    <div className={`image-button-feature-card ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="image-button-image section-Text"
      />
      <h2 className="image-button-text">{props.heading}</h2>
      <span className="image-button-text1">{props.text}</span>
    </div>
  )
}

ImageButton.defaultProps = {
  rootClassName: '',
  image_alt: 'image',
  text: 'Text',
  heading: 'Lorem ipsum',
  image_src:
    'https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHllbGxvdyUyMHRlY2h8ZW58MHx8fHwxNjI2MjU1NDk0&ixlib=rb-1.2.1&w=200',
}

ImageButton.propTypes = {
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  text: PropTypes.string,
  heading: PropTypes.string,
  image_src: PropTypes.string,
}

export default ImageButton
