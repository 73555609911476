import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <div className="footer-container1">
        <div className="footer-product-container">
          <Link to="/" className="footer-navlink">
            {props.text}
          </Link>
          <Link to="/quote" className="footer-navlink1">
            {props.text1}
          </Link>
          <Link to="/inventory" className="footer-navlink2">
            {props.text2}
          </Link>
          <Link to="/contact" className="footer-navlink3">
            {props.text3}
          </Link>
          <Link to="/contact" className="footer-navlink4">
            {props.text4}
          </Link>
        </div>
        <div className="footer-section-separator"></div>
        <div className="footer-container2">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="footer-image"
          />
          <div className="footer-socials">
            <span className="footer-text">{props.text9}</span>
            <div className="footer-icon-group">
              <a
                href="https://www.instagram.com/preferred.marble/"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="footer-icon"
                >
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                    className=""
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/preferred.marble"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="footer-icon2"
                >
                  <path
                    d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
                    className=""
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-section-separator1"></div>
        <div className="footer-product-container1">
          <span className="footer-text01">{props.text5}</span>
          <span className="footer-text02">{props.text6}</span>
          <span className="footer-text03">{props.text7}</span>
          <span className="footer-text04">{props.text8}</span>
        </div>
      </div>
      <div className="footer-separator"></div>
      <span className="footer-text05">
        <span className="">© 2022 Preferred Marble &amp; Granite, LLC</span>
        <span className="">, All Rights Reserved.</span>
        <span className="footer-text08"></span>
        <span className=""></span>
      </span>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  text2: 'Inventory',
  text: 'Home',
  text4: 'Contact',
  text1: 'Quote',
  text8: 'S: Closed',
  text3: 'Gallery',
  text9: 'Follow Us',
  image_alt: 'logo',
  text6: 'M-F: 10:00-4:00',
  text7: 'S: 10:00-1:00',
  image_src:
    'https://static.wixstatic.com/media/113df6_ec65e722ad3f4bf194fbb455ec761378~mv2.png/v1/fill/w_110,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Preffered%20transparent.png',
  text5: 'Hours',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  text2: PropTypes.string,
  text: PropTypes.string,
  text4: PropTypes.string,
  text1: PropTypes.string,
  text8: PropTypes.string,
  text3: PropTypes.string,
  text9: PropTypes.string,
  image_alt: PropTypes.string,
  text6: PropTypes.string,
  text7: PropTypes.string,
  image_src: PropTypes.string,
  text5: PropTypes.string,
}

export default Footer
