import React, { Component } from "react";

import { Helmet } from "react-helmet";

import Header from "../../widgets/header";
import Footer from "../../oldcomponents/footer";
import "./../style.css";
import "./../../oldpages/basic-quote.css";
import sinkData from "./../../data/sinks.json";
import inventoryData from "./../../data/inventory.json";
import edgeData from "./../../data/edges.json";
import ImageButton from "./../../oldcomponents/image-button";
import Lightbox from "react-image-lightbox";
import { Modal } from "react-responsive-modal";
import { isMobile } from "react-device-detect";
import 'react-responsive-modal/styles.css';

const styles1 = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

class App extends Component {
  constructor(props) {
    super(props);
    this.setModalData = this.setModalData.bind(this);
    this.next = this.next.bind(this);
    this.submit = this.submit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);

    this.sinkUpdate = this.sinkUpdate.bind(this);
    this.selectStone = this.selectStone.bind(this);
    this.selectEdge = this.selectEdge.bind(this);

    this.state = {
      isMobile: isMobile,
      isOpen: false,
      sinkData: sinkData,
      edgeData: edgeData,
      inventoryData: inventoryData,
      fc: "",
      pc: "none",
      open: false,
      showInventory: false,
      showSinks: false,
      showEdges: false,
      uploadedFile: null,

      stoneTypes: ["Granite", "Porcelain", "Quartz", "Marble", "Quartzite"],
      stoneSelection: {
        image:
          "https://cdn.msisurfaces.com/images/colornames/white-sparkle-granite.jpg",
        name: null,
      },
      stoneFilter: "Any Material",

      edgeSelection: {
        edgeType: null,
        image:
          "https://www.hardrockstoneworks.com/estimator/react-app/image/edge/bevel_1.png",
      },

      sinkTypes: ["Kitchen", "Bar", "Vanity"],
      sinkFilter: "Any Sink",
      sinkSelection: {
        Kitchen: {},
        Bar: {},
        Vanity: {},
        "Own Sink": {},
      },

      modalData: {
        name: "Loading",
        description: "Loading",
        images: [],
      },
    };
  }

  setModalData(name, description, images) {
    this.setState({
      modalData: { name: name, description: description, images: images },
      isOpen: true,
    });
    return true;
  }

  sinkUpdate(t, name, amount) {
    var sinks = this.state.sinkSelection;

    if (!sinks[t][name]) {
      sinks[t][name] = 0;
    }

    sinks[t][name] += amount;

    if (sinks[t][name] <= 0) {
      delete sinks[t][name];
    }

    this.setState({ sinkSelection: sinks });
  }

  selectStone(piece) {
    this.setState({ stoneSelection: piece, open: false });
  }

  selectEdge(piece) {
    this.setState({ edgeSelection: piece, open: false });
  }

  next(e) {
    e.preventDefault();
    this.setState({ fc: "none", pc: "" });
  }

  submit(e) {
    e.preventDefault();

    try {
      var data = {
        name: this.name.value,
        email: this.email.value,
        phoneNumber: this.phoneNumber.value,
        address: this.address.value,
        sqft: this.sqft.value,
        area: this.area.value,
        slab: this.state.stoneSelection.name,
        edge: this.state.edgeSelection.edgeType,
        sink: this.state.sinkSelection,
      };

      if (this.state.uploadedFile) {
        data.uploadedFile = this.state.uploadedFile;
      }
      fetch("/submitBasicQuote", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json",
        },
      });

      alert("Submitted! We will get back to you ASAP!");

      return true;
    } catch (e) {
      alert(e);
      return false;
    }

    //this.setState({ pc: 'none' })
  }

  uploadFile(e) {
    e.preventDefault();
    let files = e.target.files;
    
    let reader = new FileReader();
    reader.onload = (r) => {
      this.setState({ uploadedFile: r.target.result });
    };
    reader.readAsDataURL(files[0]);
  }

  onOpenModal(t) {
    var showSlab = false;
    var showEdge = false;
    var showSink = false;
    if (t === "slab") {
      showSlab = true;
    } else if (t == "edge") {
      showEdge = true;
    } else if (t == "sink") {
      showSink = true;
    }

    this.setState({
      open: true,
      showInventory: showSlab,
      showEdges: showEdge,
      showSinks: showSink,
    });
  }

  onCloseModal() {
    this.setState({ open: false });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <>
        <div className="basic-quote-container">
          <Helmet>
            <title>BasicQuote - PreferredMarble</title>
            <meta property="og:title" content="BasicQuote - PreferredMarble" />
          </Helmet>
          <div
            data-role="Header"
            className="basic-quote-navbar-container"
          ></div>
          <Header rootClassName="root-class-name8"></Header>
          <div className="basic-quote-get-in-touch">
            <h2 className={` basic-quote-text section-Heading `}>
              Request a Quote!
            </h2>
            <div className="basic-quote-content-container">
              <div
                style={{ display: this.state.fc }}
                className={
                  "basic-quote-form-container animate__animated animate__fadeInRightBig "
                }
              >
                <span className={` basic-quote-heading bigCard-Heading `}>
                  Customer Information
                </span>

                <input
                  type="text"
                  required="true"
                  name="name"
                  ref={(node) => (this.name = node)}
                  placeholder="Name"
                  className={`basic-quote-name input `}
                />
                <input
                  type="text"
                  ref={(node) => (this.email = node)}
                  required="true"
                  placeholder="E-mail"
                  className={`basic-quote-email input `}
                />
                <input
                  ref={(node) => (this.phoneNumber = node)}
                  type="text"
                  required="true"
                  placeholder="Phone Number"
                  className={`basic-quote-email1 input`}
                />

                <input
                  type="text"
                  required="true"
                  ref={(node) => (this.address = node)}
                  placeholder="Address"
                  className={`basic-quote-email2 input`}
                />
                <button
                  onClick={this.next}
                  className={`basic-quote-cta-btn anchor button`}
                >
                  NEXT
                </button>
              </div>
              <div
                style={{ display: this.state.pc }}
                className={
                  "basic-quote-form-container1 animate__animated animate__fadeInRightBig "
                }
              >
                <span className={`basic-quote-heading1 bigCard-Heading`}>
                  Project Information
                </span>

                <select
                  name="Area Description"
                  ref={(node) => (this.area = node)}
                  id="cars"
                  className={`basic-quote-name1 input"]} `}
                >
                  <option value="General">General Area</option>
                  <option value="Bathroom">Bathroom</option>
                  <option value="Kitchen">Kitchen</option>
                  <option value="Fireplace">Fireplace</option>
                  <option value="Bar">Bar</option>
                </select>

                <input
                  type="text"
                  required="false"
                  ref={(node) => (this.sqft = node)}
                  placeholder="Approximate Square Footage"
                  className={`basic-quote-email4 input `}
                />

                <div className="basic-quote-slab-selection">
                  <label className={`basic-quote-text1 input `}>
                    SLAB SELECTION:
                  </label>
                  <button
                    style={{
                      backgroundColor:
                        this.state.stoneSelection.name != null ? "green" : "",
                    }}
                    onClick={(e) => {
                      this.onOpenModal("slab");
                    }}
                    className={` basic-quote-button button `}
                  >
                    SELECT A SLAB
                  </button>
                </div>
                <div className="basic-quote-sink-selection">
                  <label className={` basic-quote-text2 input `}>
                    EDGE PROFILE:
                  </label>
                  <button
                    style={{
                      backgroundColor:
                        this.state.edgeSelection.edgeType != null
                          ? "green"
                          : "",
                    }}
                    onClick={(e) => {
                      this.onOpenModal("edge");
                    }}
                    className={` basic-quote-button1 button `}
                  >
                    SELECT AN EDGE
                  </button>
                </div>
                <div className="basic-quote-edge-profile">
                  <label className={` basic-quote-text3 input `}>
                    SINK SELECTION:
                  </label>
                  <button
                    style={{
                      backgroundColor:
                        Object.keys(this.state.sinkSelection.Bar).length > 0 ||
                        Object.keys(this.state.sinkSelection.Kitchen).length >
                          0 ||
                        Object.keys(this.state.sinkSelection.Vanity).length >
                          0 ||
                        Object.keys(this.state.sinkSelection["Own Sink"])
                          .length > 0
                          ? "green"
                          : "",
                    }}
                    onClick={(e) => {
                      this.onOpenModal("sink");
                    }}
                    className={` basic-quote-button2 button `}
                  >
                    SELECT A SINK
                  </button>
                </div>
                <div className="basic-quote-edge-profile">
                  <label className={` basic-quote-text3 input `}>
                    UPLOAD FILE:
                  </label>

                  <input
                    style={{
                      backgroundColor:
                        this.state.uploadedFile != null ? "green" : "",
                    }}
                    className={` basic-quote-button2 button `}
                    type="file"
                    name="file"
                    onChange={(e) => this.uploadFile(e)}
                  />
                </div>
                <button
                  onClick={this.submit}
                  className={` basic-quote-cta-btn1 anchor button `}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
          <Footer rootClassName="root-class-name3"></Footer>
        </div>

        <div style={styles1}>
          <Modal open={this.state.open} onClose={this.onCloseModal}>
            <div className={this.state.isMobile ? "" : "inventory_gallery"}>
              {this.state.showInventory &&
                this.state.inventoryData.map((piece, id) => {
                  return (
                    <div
                      class="colr"
                      onClick={(e) => {
                        this.selectStone(piece);
                      }}
                      style={{ position: "relative" }}
                    >
                      <ImageButton
                        heading={piece.name}
                        text={piece.stone + " " + piece.thickness}
                        image_src={piece.image}
                        rootClassName="rootClassName7"
                        className="gallery-component5"
                      ></ImageButton>

                      <div
                        style={{
                          position: "absolute",
                          top: 20,
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setModalData(
                              piece.name,
                              piece.stone + " " + piece.thickness,
                              piece.image
                            );
                          }}
                        >
                          ENLARGE
                        </button>
                      </div>
                    </div>
                  );
                })}

              {this.state.showEdges &&
                this.state.edgeData.map((piece, id) => {
                  return (
                    <div
                      className="colr"
                      onClick={(e) => {
                        this.selectEdge(piece);
                      }}
                      style={{ position: "relative" }}
                    >
                      <ImageButton
                        heading={piece.edgeType}
                        text={" "}
                        image_src={piece.image}
                        rootClassName="rootClassName7"
                        className="gallery-component5"
                      ></ImageButton>

                      <div
                        style={{
                          position: "absolute",
                          top: 20,
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            this.setModalData(piece.edgeType, " ", piece.image);
                          }}
                        >
                          ENLARGE
                        </button>
                      </div>
                    </div>
                  );
                })}

              {this.state.showSinks &&
                this.state.sinkData.map((piece, id) => {
                  return (
                    <div>
                      <div
                        className="colr"
                        onClick={(e) => {
                       
                          this.setModalData(
                            piece.name + " | " + (piece.dimensions || " "),
                            piece.sinkType,
                            piece.images[0]
                          );
                        }}
                      >
                        <ImageButton
                          heading={piece.name}
                          text={piece.sinkType}
                          image_src={piece.images[0]}
                          rootClassName="rootClassName7"
                          className="gallery-component5"
                        ></ImageButton>

                        <div
                          style={{
                            padding: "10px",
                            top: "80",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();

                              this.sinkUpdate(piece.sinkType, piece.name, -1);
                            }}
                          >
                            -
                          </button>
                          <h5>
                            {(this.state.sinkSelection[piece.sinkType] &&
                              this.state.sinkSelection[piece.sinkType][
                                piece.name
                              ]) ||
                              0}
                          </h5>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();

                              this.sinkUpdate(piece.sinkType, piece.name, 1);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Modal>
          {isOpen && (
            <Lightbox
              imageCaption={this.state.modalData.name}
              mainSrc={this.state.modalData.images}
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          )}
        </div>
      </>
    );
  }
}

export default App;
