import React from 'react'

import PropTypes from 'prop-types'

import './blurb-reverse.css'

const BlurbReverse = (props) => {
  return (
    <div className={`blurb-reverse-container ${props.rootClassName} `}>
      <div className="blurb-reverse-hero">
        <div className="blurb-reverse-container1">
          <img
            alt={props.image_src}
            src={props.image_src}
            className="blurb-reverse-image"
          />
        </div>
        <div className="blurb-reverse-container2">
          <h1 className="blurb-reverse-text">{props.heading}</h1>
          <img
            alt={props.image_src}
            src={props.image_src}
            className="blurb-reverse-image1"
          />
          <span className="blurb-reverse-text1">{props.text}</span>
          <div className="blurb-reverse-btn-group">
            <button className="blurb-reverse-button button">
              <span className="blurb-reverse-text2">
                <span className="">{props.text1}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

BlurbReverse.defaultProps = {
  heading: 'Our story',
  text: "For the last 4 generations Preferred Marble & Granite has been voted #1 in Michigan and recognized as a trusted granite fabricator throughout the USA and your number one source for all things granite. Where we only employ professional, experienced installers who go through 5 years of training before handling your material. We are 1 of 2 shops in Michigan that can cut Cambria without a middle man and we also specialize in custom marble masonry! We hope you enjoy our services and products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us!",
  rootClassName: '',
  image_src: 'https://ciot.com/pub/media/banner_press_700x480.jpg',
  text1: 'See our Work',
  image_alt: 'image',
}

BlurbReverse.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
  text1: PropTypes.string,
  image_alt: PropTypes.string,
}

export default BlurbReverse
