import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './c-t-a-container.css'


const CTAContainer = (props) => {
  return (
    <div className={`c-t-a-container-c-t-a-container ${props.rootClassName} `}>
      <h1 className="c-t-a-container-text">{props.heading}</h1>
      <span className="c-t-a-container-text1">
        <span className="">
          <span className="">
            With a vast range of porcelain, marble, granite, quartz, and other
            natural stone materials, you can transform and improve your living
            environment. Our specialists provide great individual support
            regardless of the project size or scope, whether it is for indoor or
            outdoor locations. Are you ready to move beyond the basics? Allow
            Preferred Marble to assist you.
          </span>
        </span>
      </span>
      <div className="c-t-a-container-btn-group">
      <button className="c-t-a-container-button button">
          <Link to="/inventory" className="c-t-a-container-navlink1">
            {props.text}
          </Link>
        </button>
        <button className="c-t-a-container-button button">
          <Link to="/quote" className="c-t-a-container-navlink1">
            {props.text1}
          </Link>
        </button>
      </div>
    </div>
  )
}

CTAContainer.defaultProps = {
  text1: 'Get a quote',
  text: 'See our Inventory',
  rootClassName: '',
  heading: 'Lets turn your vision into a reality',
}

CTAContainer.propTypes = {
  text1: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  heading: PropTypes.string,
}

export default CTAContainer
