import React, { Component } from "react";
import './ImageGallery.scss'
import Lightbox from 'react-image-lightbox';

/*
const filters = [

  {name:"Kitchen", status: false},
  {name:"Bathroom", status: false},
  {name:"Fireplace", status: false},
  {name:"Bar", status: false},

  {name:"Granite", status: false},
  {name:"Porcelain", status: false},
  {name:"Marble", status: false},
  {name:"Quartz", status: false},
  {name:"Quartzite", status: false},
];
*/



const Filters = ({ onClickAll, all, onClick, filters }) =>
  <form>
    <ul>
      <li onClick={onClickAll}>
        <input
          type="checkbox"
          checked={all}
        />
        <label htmlFor="all">All</label>
      </li>
      {filters.map(
        (filter, i) =>
          <li key={i} data-index={i} onClick={onClick} >
            <input
              id={filter.name}
              type="checkbox"
              checked={filter.status}
            />
            <label htmlFor={filter.name}>{filter.name}</label>
          </li>)}
    </ul>
  </form>

const Cards = ({ imgs }) =>
  <ul>
    {imgs.map(
      (img, i) =>
        <li key={i}>
          <figure>

            <img src={img.srcs[0]} onClick={() => this.setState({ isOpen: true, modalData: { images: img.srcs } })} />

            <div class="container">
              <div class="text-block">
                <ul>
                  {Object.values(img.tags).map(e => {
                    return (<li>{String(e)}</li>)
                  })}
                </ul>
              </div>
            </div>

            <figcaption>
              <div>{img.author} </div>
              <span>{img.tag}</span>
            </figcaption>
          </figure>
        </li>)}
  </ul>

class App extends React.Component {


  constructor(props) {
    super(props);
    this.setAll = this.setAll.bind(this)
    this.setFilter = this.setFilter.bind(this)
    this.Cards = this.Cards.bind(this)
    this.updateFilters = this.updateFilters.bind(this)
    this.updateImgs = this.updateImgs.bind(this)

    this.state = {
      imgs: props.images,
      originalimgs: props.images,
      filters: props.filters,
      all: true,

      photoIndex: 0,
      isOpen: false,
      modalData: { images: [] }
    };
  }


  Cards = ({ imgs }) =>
    <ul>
      {imgs.map(
        (img, i) =>
          <li key={i}>
            <figure>

              <img src={img.srcs[0]} onClick={() => this.setState({ isOpen: true, modalData: { images: img.srcs } })} />

              <div class="container">
                <div class="text-block">
                  <ul>
                    {Object.values(img.tags).map(e => {
                      return (<li>{String(e)}</li>)
                    })}
                  </ul>
                </div>
              </div>

              <figcaption>
                <div>{img.name || Object.values(img.tags).join(' | ')}</div>
                <span>{(img.name ? Object.values(img.tags).join(' | ') : "")}</span>
              </figcaption>
            </figure>
          </li>)}
    </ul>


  componentDidUpdate(prevProps, prevState) {

    if (prevProps !== this.props) {
      for (var i in this.state.filters) {
        if (this.state.filters[i].status == true) {
          this.setState({ all: false },()=>{
            this.setFilter()
          })
          break
        }
      }
    }
  }

  componentDidMount() {
    this.setState({ filters: this.props.filters })
  }


  setFilter = (e) => {
    if(e){
      e.preventDefault();
    }
    const { filters, all } = this.state;

    if(e){
    var { index } = e.currentTarget.dataset;

    filters[index].status = !filters[index].status;
    }
    this.setState({
      filters
    });

    this.updateFilters();
    this.updateImgs();
  }

  setAll = () => {
    const { filters } = this.state;
    filters.forEach(
      filter => {
        filter.status = false;
      }
    );

    this.setState({
      all: true,
      filters
    });
  }

  updateFilters() {
    const allFiltersTrue = this.state.filters.every(filter => filter.status === true);
    const allFiltersFalse = this.state.filters.every(filter => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false
      });
    }
  }



  updateImgs() {
    const { filters, all } = this.state;
    let newImgs = [];



    this.props.images.forEach((img, imgKey) => {
      var valid = false

      filters.forEach((filter, filterKey) => {
        if (filter.status == true) {
          for (var x in img.tags) {
            if (img.tags[x] == filter.name) {
              valid = true
            }
          }
        }
      })
      if (valid == true) {
        newImgs.push(img)
      }
    });

    this.setState({
      imgs: newImgs
    }, () => {
      console.log(this.state.imgs)
    });
  }

  render() {
    const { filters, all, photoIndex, isOpen } = this.state;
    return (
      <div className="ImageGallery">


        {isOpen && (
          <Lightbox
            activeProps={{ width: '100%', height: '100%' }}
            mainSrc={this.state.modalData.images[photoIndex]}
            nextSrc={(this.state.modalData.images.length > 1 ? this.state.modalData.images[(photoIndex + 1) % this.state.modalData.images.length] : null)}
            prevSrc={(this.state.modalData.images.length > 1 ? this.state.modalData[(photoIndex + this.state.modalData.images.length - 1) % this.state.modalData.images.length] : null)}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.state.modalData.images.length - 1) % this.state.modalData.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.state.modalData.images.length,
              })
            }
          />
        )}

       {this.state.filters && <Filters
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters} />

       }

       
        {(this.props.images || this.state.imgs) && ((all) ? (
          <this.Cards imgs={this.props.images} />
        ) : (
          <this.Cards imgs={this.state.imgs} />
        ))}

      </div>
    );
  }
}
export default App