import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../../widgets/header'
import CTAContainer from '../../components/c-t-a-container/c-t-a-container'
import Blurb from '../../components/blurb/blurb'
import BlurbReverse from '../../components/blurb-reverse/blurb-reverse'
import Footer from '../../widgets/footer'
import './home.css'
import '../style.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>PreferredMarble</title>
        <meta property="og:title" content="PreferredMarble" />
      </Helmet>

      <Header rootClassName="root-class-name"></Header>
      <div className="home-hero">
          <CTAContainer rootClassName="c-t-a-container-root-class-name1"></CTAContainer>
      </div>
      <div className={"home-c-t-a-container"}>
        <h1 className={"home-text"}>Lets turn your vision into a reality</h1>
        <span className={"home-text01"}>
          <span>
            <span>
              With a vast range of porcelain, marble, granite, quartz, and other
              natural stone materials, you can transform and improve your living
              environment. Our specialists provide great individual support
              regardless of the project size or scope. Whether it is for indoor
              or outdoor locations. Are you ready to move beyond the basics?
              Allow Preferred Marble to assist you.
            </span>
          </span>
        </span>
        <div className={"home-btn-group"}>
          <Link
            to="/inventory"
            className={` home-navlink button `}
          >
            <span className="home-text04">See our Inventory</span>
          </Link>
          <Link
            to="/quote"
            className={` home-navlink button `}
          >
            <span className="home-text04">Get a quote</span>
          </Link>
        </div>
      </div>

      
        <Blurb
          rootClassName="root-class-name"
          image_src="https://i.imgur.com/udHagq0.png"
          className="home-component2"
          text={"For the last 4 generations, Preferred Marble & Granite has been voted #1 in Michigan and recognized as a trusted granite fabricator throughout the USA and your number one source for all things granite, marble and porcelain. Where we only employ professional, experienced installers who go through 5 years of training before handling your material. We are 1 of 2 shops in Michigan that can cut Cambria without a middle man and we also specialize in custom marble masonry! We hope you enjoy our services and products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us!"}
          text1={<Link to="/gallery" className="home-navlink2">See our work</Link>}
        ></Blurb>
     
     
      <BlurbReverse
        text1={ <Link to="/quote" className="home-navlink2">Start my estimate</Link>}
        heading="Our Process"
        rootClassName="root-class-name"
        image_src="https://i.imgur.com/rqwRGoI.png"
        text="Ready to design the home of your dreams? From design to installation, we are here to walk you through it! Click below to get started!"
      ></BlurbReverse>
      <div id="process" className="home-services">
        <div className="home-heading-container">
          <h1
            className={` home-text05 section-Heading `}
          >
            Get Started with us
          </h1>
          <span
            className={` home-text06 section-Text `}
          >
            Lets work together to get your dream project together.
          </span>
        </div>
        <div className={"home-cards-container"}>
          <div className={"home-service-card"}>
            <h3
              className={` home-text07 card-Heading `}
            >
              Start an estimate
            </h3>
            <span
              className={` home-text08 card-Text `}
            >
              Use our virtual estimator tool to get your next project going!
            </span>
            <Link
              to="/quote"
              className={`home-navlink3 button `}
            >
              <span className="home-text09">Lets go</span>
            </Link>
          </div>
          <div className="home-service-card1">
            <h3
              className={` home-text10 card-Heading `}
            >
              Check out our Inventory
            </h3>
            <span
              className={`home-text11 card-Text `}
            >
              We have a very wide range to choose from. Don&apos;t see anything
              you like? Contact us, we will help you find what you&apos;re
              looking for!
            </span>
            <button
              className={` home-button1 button `}
            >
              <Link to="/inventory" className="home-navlink4">
                Lets go
              </Link>
            </button>
          </div>
          <div className="home-service-card2">
            <h3
              className={` home-text12 card-Heading `}
            >
              See our Gallery
            </h3>
            <span
              className={`home-text13 card-Text `}
            >
              Check out our projects!
            </span>
            <button
              className={` home-button2 button `}
            >
              <Link to="/gallery" className="home-navlink4">
              
              
              <span className="home-text14">
                <span>lets go</span>
              </span>
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="home-section-seperator"></div>
      <div className="home-section-seperator1"></div>
      <div className="home-section-seperator2"></div>
      <div className="home-section-seperator3"></div>
      <div className="home-banner">
        <h2 className="home-text16">
         
What are you waiting for?
      
        </h2>
        <h1 className="home-text19">Let&apos;s Collaborate!</h1>
       <br/>
         <Link to="/contact" className={` home-button3 button `} >
            CONTACT US
          </Link>
      </div>
      <div className="home-separator"></div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
