import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


import Header from "../../widgets/header";
import Footer from "../../oldcomponents/footer";
import Blurb from '../../components/blurb/blurb'
import BlurbReverse from '../../components/blurb-reverse/blurb-reverse'
import InventoryData from "./../../data/inventory.json";
import ImageButton from "./../../oldcomponents/image-button";
import "./../style.css";
import "./../../oldpages/inventory.css";
import "./inventory.css";

import ImageGallery from "../../components/ImageGallery/ImageGallery"


class Gallery extends Component {
  constructor(props) {
    super(props);
 this.filterStone = this.filterStone.bind(this)

    this.state = {
      images: [],
      selected:false,
      filters: 
          [

{ name: "Granite", status: false },
            { name: "Porcelain", status: false },
            { name: "Marble", status: false },
            { name: "Quartz", status: false },
            { name: "Quartzite", status: false }]
  
  }

}


filterStone(stone){
 
  var a= this.state.filters
  for (var i in a){
    if(a[i].name == stone){
      a[i].status = true
    }else{
      a[i].status = false
    }
  }

  this.setState({filters:a,selected:true})
}

  componentDidMount() {
    var t = []
   for(var i in InventoryData){
    t.push({srcs:[InventoryData[i].image],tags:[InventoryData[i].stone,InventoryData[i].thickness],name:InventoryData[i].name})
   }

   this.setState({images:t})
  }

  render() {
    
    return (
      <div className="inventory-container">
        <Helmet>
          <title>Inventory - PreferredMarble</title>
          <meta property="og:title" content="Inventory - PreferredMarble" />
        </Helmet>
        <div data-role="Header" className="inventory-navbar-container">
          <Header rootClassName="root-class-name1"></Header>
          <div
            className="animate__animated animate__backInLeft"
            onClick={(e) => {
              this.filterStone("Marble");
            }}
          >
            <Blurb
              rootClassName="blurb-root-class-name1"
              text1={
                <a href="#images" className="gallery-link3">
                  see our marble
                </a>
              }
              heading={"marble"}
              image_src="https://i.imgur.com/1LQjn2q.jpeg"
              text="The cool, smooth, loveliness of marble has been the choice of kings and artists for centuries. In countries such as Italy and India, marble lines the halls, walls and floors of the most beautiful structures. Marble is nature’s masterpiece. Its natural patterns and colors occur thanks to the mineral deposits present during its formation. Clay, mica, sand, iron oxides and other natural materials create exquisite hues of red, pink, brown, blue, grey and green as well as unique veining in white or lighter shades. Marble is ideal for crafting elegant details such as arches and curves. After all, artists Michelangelo and Donatello created their most famous works with marble, specifically the translucent white marble of Carrara, Italy."
            ></Blurb>
          </div>
          <div
            className="animate__animated animate__backInRight"
            onClick={(e) => {
              this.filterStone("Granite");
            }}
          >
            <BlurbReverse
              rootClassName="blurb-reverse-root-class-name1"
              heading="granite"
              text1={
                <a href="#images" className="gallery-link3">
                  see our granite
                </a>
              }
              image_src="https://puu.sh/IQs0u/07b8693369.jpeg"
              text="Granite is one of the world's most powerful and long-lasting natural materials. Granite's tremendous hardness, which is comparable to diamonds, provides it a very long-lasting surface. Every slab is a one-of-a-kind, timeless option for updating any area in your house. Like most things in nature, it has a variety of colors and patterns. Granite that has been properly sealed and maintained will preserve its beauty and strength with ease."
            ></BlurbReverse>
          </div>
          <div
            className="animate__animated animate__backInLeft"
            onClick={(e) => {
              this.filterStone("Quartz");
            }}
          >
            <Blurb
              rootClassName="blurb-root-class-name2"
              text1={
                <a href="#images" className="gallery-link3">
                  see our quartz
                </a>
              }
              heading="quartz"
              image_src="https://i.imgur.com/0JOX5R1.png"
              text="Quartz is a man-made stone composed of approximately 95% crushed natural stone and 5% binding substance such as polymer or cement-based resins. The end product is a material that is both robust and elegant. Quartz countertops are also available in almost any hue and can contain special elements like glitter, tinted glass, and even semiprecious stones. Quartz has apparently unlimited color and pattern variations, which is why it's a favorite among interior designers. A designer can easily emulate genuine stone like granite or marble using quartz, or use eye-catching colors like fire-engine red or deep blue. Natural crystals and tinted glass are examples of reflective materials that can be used to add greater luster."
            ></Blurb>
          </div>
          <div
            className="animate__animated animate__backInRight"
            onClick={(e) => {
              this.filterStone("Quartzite");
            }}
          >
            <BlurbReverse
              rootClassName="blurb-reverse-root-class-name2"
              heading="quartzite"
              text1={
                <a href="#images" className="gallery-link3">
                  see our quartzite
                </a>
              }
              image_src="https://i.imgur.com/lHaESvQ.png"
              text="Quartzite is a miraculous natural stone that we like for its use and beauty. We take special effort to guarantee that the distinctive characteristics of quartzite are the focal point of your stone countertop. Quartzite is frequently mistaken for marble because of its exquisite beauty, which includes intricate patterns and veining. Quartzite holds up well under the strain of heavy use. In other words, it's a culinary all-star that's scratch, chip, and heat resistant to a fault. Quartzite is an excellent choice if you want the aesthetic of marble but require a firm, durable stone for a high-traffic area like your kitchen. Its beautiful texture and coloration, especially in colors like Taj Mahal, Fusion, Macaubus Fantasy, and Madre Perla, are frequently mistaken for marble."
            ></BlurbReverse>
          </div>
          <div
            className="animate__animated animate__backInLeft"
            onClick={(e) => {
              this.filterStone("Porcelain");
            }}
          >
            <Blurb
              rootClassName="blurb-root-class-name3"
              text1={
                <a href="#images" className="gallery-link3">
                  see our porcelain
                </a>
              }
              heading="porcelain"
              image_src="https://i.imgur.com/5yhI02Y.png"
              text="When compared to tile, porcelain has a delightfully smooth, grout-free texture. It's virtually maintenance-free and always looks wonderful because there's no grout to scrape. Porcelain is a great surface for kitchens and bathrooms since it is non-porous. Bacteria can't get through its surface, and it's simple to clean with mild soap or our Stone Defender disinfecting solution. As a result, porcelain is extremely stain resistant. Porcelain's glossy sheen is enticing, and this sturdy, flexible ceramic material comes in a range of finishes."
            ></Blurb>
          </div>
          <div className="animate__animated animate__backInRight">
            <BlurbReverse
              rootClassName="blurb-reverse-root-class-name2"
              heading="Sinks"
              text1={
                <Link
            to="/sinks"
            
          >
            <span className="home-text04">See our Sinks</span>
          </Link>
              }
              image_src="https://i.imgur.com/ZtpXeNy.png"
              text="We have a large and diverse selection of sinks because they play a significant role in your decor. Traditional, modern, stainless steel, composite, ceramic, as well as a variety of sizes, colors, and shapes. A durable stone countertop must also have the proper sink installed in the proper manner."
            ></BlurbReverse>
          </div>
          <div className="animate__animated animate__backInRight">
            <Blurb
              rootClassName="blurb-root-class-name3"
              heading="Cambria"
              text1={
                <a
                  href="https://www.cambriausa.com/quartz-colors/#!/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Visit Cambria
                </a>
              }
              image_src="https://i.imgur.com/fVL9IBu.png"
              text="Preferred Marble and Granite is very proud to announce that we are a certified fabricator for Cambria Surfaces in the state of Michigan. Cambria concentrates on sourcing a large part of quartz from a unique mine that they own. This creates characteristics within the design that cannot be matched. Cambria surfaces are designed to be harder than granite and marble. This means that the surface are less likely to scratch, chip or stain. Unlike Granite and Marble, Cambria Quartz does not require regular sealing and polishing. To maintain Cambria Quartz, just wipe with warm water and mild soap. Cambria Quartz is nonporous and nonabsorbent which means that it will not draw in moisture foods or liquids that can harbor harmful bacteria. Cambria Quartz is manufactured in the United States by a family owned company out of Minnesota."
            ></Blurb>
          </div>
          <div className="animate__animated animate__backInLeft">
            <BlurbReverse
              rootClassName="blurb-root-class-name4"
              text1={<Link to="/contact">contact us</Link>}
              image_src="https://i.imgur.com/8RQmU39.png"
              heading="don't see anything you like?"
              text="Preferred Marble & Granite makes it a priority to be sure that your experience meets all of your expectations. We are more than happy to work with any suppliers that are not listed on our website. Please let us know the supplier name and the material color name in the “Contact us” page."
            ></BlurbReverse>
          </div>
          <div className="animate__animated animate__backInRight">
            <Blurb
              rootClassName="blurb-reverse-root-class-name2"
              heading={<Link to="/contact">Care and Maintenance</Link>}
              text1="Contact us"
              image_src="https://i.imgur.com/NFn2pSj.png"
              text="Some general ways to take care of your granite include the following: CLEAN REGULARLY WITH A MILD DISH SOAP, AVOID ABRASIVES AND ACIDS, WIPE UP SPILLS (INCLUDING WATER), USE A CUTTING BOARD FOR FOOD PREP, PLACE HOT PANS ON TRIVETS OR PADS. Please also note that granite, quartzite, marble and porcelain are heat resistant, however direct application of high heat for longer periods could damage your stone. Do not apply direct heat to quartz. If you have any more questions about how to take care of your material, please don’t hesitate to contact us!"
            ></Blurb>
          </div>
        </div>
<div id="images"></div>
        {this.state.images && <ImageGallery images={this.state.images} filters={this.state.filters}></ImageGallery>
            }


        <Footer />
      </div>
    );
  }
}

export default Gallery;
