import React from 'react';
import ReactDOM from 'react-dom/client';

import Home from './pages/home/home';
import Inventory from './pages/inventory/inventory'
import Contact from './pages/contact/contact'
import Gallery from './pages/gallery/gallery'
import Quote from './pages/quote/basicQuote'
import Sinks from './pages/sinks/sinks'
//import Quote from './pages/quote/basicQuote'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
      <Route exact element={<Home/>} path="/" />
      <Route  element={<Gallery/>} path="/gallery" />
      <Route  element={<Inventory/>} path="/inventory" />
      <Route  element={<Contact/>} path="/contact" />
      <Route  element={<Quote/>} path="/quote" />
      <Route element={<Sinks/>} path="/sinks"/>
    
      </Routes>
    
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/*
 <Route exact component={BasicQuote} path="/quote" />
        <Route exact component={Contact} path="/contact" />
        <Route exact component={Inventory} path="/inventory" />
        <Route exact component={Gallery} path="/gallery" />
        <Route exact component={Sinks} path="/sinks" />
*/