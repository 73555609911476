import React, { Component } from "react";
import "./sinks.css";

import "./../style.css";
import Header from '../../widgets/header'
import Footer from '../../oldcomponents/footer'

import ImageGallery from "../../components/ImageGallery/ImageGallery"
import { Helmet } from "react-helmet";
import SinkData from "./../../data/sinks.json";


class Gallery extends Component {
  constructor(props) {
    super(props);
  
    this.state = {

     
    };
  }



  componentDidMount() {
    var images = []
    for(var i in SinkData){
      images.push({tags:[SinkData[i].sinkType],srcs:SinkData[i].images,name:SinkData[i].name})
      var a = { tags: [], srcs: [] }
    }
    this.setState({images:images})
  }

  closeLightbox() {
    this.setState({
      isOpenA: false,
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  openLightbox(index, event) {
    this.setState({ isOpen: true });
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div className="gallery-container">
        <Helmet>
          <title>Gallery - PreferredMarble</title>
          <meta property="og:title" content="Gallery - PreferredMarble" />
        </Helmet>
        <Header />

       {this.state.images &&  <ImageGallery images={this.state.images} filters={[

{name:"Kitchen", status: false},
{name:"Vanity", status: false},
{name:"Bar", status: false},

]}></ImageGallery>}

        <Footer></Footer>
       </div>
    );
  }
}

export default Gallery;
