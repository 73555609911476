import React, { Component } from "react";
import Header from '../../widgets/header'
import Footer from '../../oldcomponents/footer'

import App from "../../components/ImageGallery/ImageGallery";
//import "./gallery.css";
import ImageGallery from "../../components/ImageGallery/ImageGallery"


async function requestImage(path) {
  return new Promise((resolve, reject) => {
    fetch(path).then(e => {
      resolve(e)
    })
  });
}

async function getImageConfig(path) {
  return new Promise((resolve, reject) => {
    fetch(path, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function (response) {
      return response.json()
    }).then(function (j) {
      resolve(j)
    })

  })

}



class Gallery extends Component {
  constructor(props) {
    super(props);

    this.organize = this.organize.bind(this)

    this.state = {
      images: []
    };




  }


  organize(data) {

    return new Promise(async (resolve, reject) => {
      var set = []
      for (var i in data) {
        var a = { tags: [], srcs: [] }
        for (var x in data[i].children) {
          if (data[i].children[x].path.split('.')[1] == "png" ||data[i].children[x].path.split('.')[1] == "jpg") {
            //console.log()
            var str = data[i].children[x].path
            var arr = str.split("/");
            a.src = data[i].children[x].path
            arr.shift();
            str = arr.join("/");

            a.srcs.push(str)
          } else {
            var str = data[i].children[x].path
            var arr = str.split("/");
            arr.shift();
            str = arr.join("/");

            var b = await getImageConfig(str)
            a.tags = b.tags

          }
        }
        //console.log(set)

        if (a.tags.area && a.tags.stone ) {// && a.srcs.length>1
          this.setState({ images: [...this.state.images, a] })
        }
        // console.log(this.state.images)
        //break
      }

    })

  }


  componentDidMount() {
    fetch("/getEntireGallery")
      .then((response) => response.json())
      .then(async (data) => {
        var pics = []

        var organize = await this.organize(data)

        //this.setState({images:[...this.state.images,a]})
      });
  }

  render() {


    return (


      <div>
        <Header></Header>

        {this.state.images && <ImageGallery images={this.state.images} filters={
          [
            { name: "Kitchen", status: false },
            { name: "Bathroom", status: false },
            { name: "Fireplace", status: false },
            { name: "Bar", status: false },
            { name: "Other", status: false }]}></ImageGallery>
            }

        <Footer></Footer>
      </div>



    );
  }
}

export default Gallery;
