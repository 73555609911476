import React, { Component } from "react";

import { Helmet } from "react-helmet";

import Header from "../../widgets/header";
import Footer from "../../oldcomponents/footer";
import "./../style.css";
import "./../../oldpages/contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);

    this.state = {
      active: false,
    };
  }

  toggleClass() {
    this.setState({ active: !this.state.active });

    fetch("/submitContact", {
      method: "POST",
      body: JSON.stringify({
        name: this.name.value,
        email: this.email.value,
        phone: this.phoneNumber.value,
        message: this.message.value,
      }),
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  render() {
    return (
      <div className="contact-container">
        <Helmet>
          <title>Contact - PreferredMarble</title>
          <meta property="og:title" content="Contact - PreferredMarble" />
        </Helmet>
        <div data-role="Header" className="contact-navbar-container">
          <Header />
        </div>
        <div className="contact-get-in-touch">
          <h2 className={` contact-text section-Heading `}>Get in touch</h2>
          <div className="animate__animated animate__backInRight contact-content-container">
            <div
              style={{
                textAlign: "center",
                display: this.state.active ? "block" : "none",
              }}
              className="animate__animated animate__fadeIn contact-message-sent"
            >
              <span className={` contact-heading bigCard-Heading `}>
                Message sent! We will be in contact with you shortly!
              </span>
            </div>
            <div
              className="contact-form-container"
              style={{ display: this.state.active ? "none" : "block" }}
            >
              <span className={` contact-heading1 bigCard-Heading `}>
                Send us a message
              </span>
              <input
                ref={(node) => (this.name = node)}
                type="text"
                required="true"
                placeholder="Name"
                className={` contact-name input `}
              />
              <input
                ref={(node) => (this.phoneNumber = node)}
                type="text"
                required="true"
                placeholder="Phone Number"
                className={` contact-name input `}
              />
              <input
                ref={(node) => (this.email = node)}
                type="text"
                required="true"
                placeholder="E-mail"
                className={` contact-email input `}
              />
              <textarea
                ref={(node) => (this.message = node)}
                placeholder="Your Message"
                className={` contact-message textarea `}
              ></textarea>
              <button
                onClick={(e) => {
                  this.toggleClass();
                }}
                className={` contact-cta-btn button anchor `}
              >
                SEND
              </button>
            </div>
            <div className="contact-locations-container">
              <div className="contact-location-1">
                <span className="contact-heading2">Michigan, USA</span>
                <div className="contact-adress">
                  <svg viewBox="0 0 1024 1024" className="contact-icon">
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                  <span className="section-Text">
                    32205 Groesbeck Hwy, Fraser, MI 48026
                  </span>
                </div>
                <div className="contact-email1">
                  <svg viewBox="0 0 1024 1024" className="contact-icon2">
                    <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                  </svg>
                  <span className="section-Text">
                    estimating@preferredmarble.com
                  </span>
                </div>
                <div className="contact-phone">
                  <svg
                    viewBox="0 0 804.5714285714286 1024"
                    className="contact-icon4"
                  >
                    <path d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"></path>
                  </svg>
                  <span className="section-Text">(586) 294-6360</span>
                </div>
              </div>
              <div className="contact-location-11">
                <span className="contact-heading3">
                  Schedule an appointment with us
                </span>
                <div className="contact-phone1">
                  <svg
                    viewBox="0 0 804.5714285714286 1024"
                    className="contact-icon6"
                  >
                    <path d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"></path>
                  </svg>
                  <span className="section-Text">(586) 294-6360</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
